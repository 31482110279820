import http from "../http";

export const paymentList = params => {
  return http({
    method: "get",
    url: `/api/settlement/list`,
    params
  });
};
export const downloadSettleFile = params => {
  return http({
    method: "get",
    url: `/api/settlement/settlefile`,
    params,
    responseType: "blob"
  });
};
export const paymentShopList = params => {
  return http({
    method: "get",
    url: `/api/settlement/shopsettle`,
    params
  });
};
//调用结算服务查询结算列表，包含POINT
export const depositList = params => {
  return http({
    method: "get",
    url: `/api/settlement/depositlist`,
    params
  });
};
//调用结算服务查询结算详情，包含POINT
export const depositShopDetail = params => {
  return http({
    method: "get",
    url: `/api/settlement/depositshopdetail`,
    params
  });
};

export const downloadDepositFile = params => {
  return http({
    method: "get",
    url: `/api/settlement/depositfile`,
    params,
    responseType: "blob"
  });
};
