//下载
export function executeDownload(response) {
  //提取文件名
  const content = response.headers["content-disposition"];
  let name1 = content.match(/filename=(.*);/)[1]; // 获取filename的值
  let name2 = content.match(/filename\*=(.*)/)[1]; // 获取filename*的值 RFC 5987
  name1 = decodeURIComponent(name1);
  name2 = decodeURIComponent(name2.substring(7)); // 去掉UTF-8''
  let fileName = "";
  if (!name2 || name2.length > 0) {
    fileName = name2;
  } else {
    fileName = name1;
  }
  // 将二进制流转为blob
  const blob = new Blob([response.data], { type: "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob);
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", fileName);
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    // 挂载a标签
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL);
  }
}
