<template>
  <div>
    <div class="payment-title">
      {{ $t("receipt") }}
    </div>
    <div class="search-query-container">
      <div class="search-query">
        <el-form>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item class="app-search-label" :label="$t('paymentDateRange')">
                <search-date class="search-date" :data.sync="searchDate" :format="format" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item class="app-search-label" :label="$t('accountInfo')">
                <el-select v-model="acctName" style="width: 100%;">
                  <el-option v-bind:label="$t('all')" value=""> </el-option>
                  <el-option
                    v-for="item in accountList"
                    :key="item.acctName"
                    :label="`${item.bankName} ${item.branchName} ${item.acctNo}`"
                    :value="item.acctName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchData">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>
    </div>

    <div class="result-title">{{ $t("tablePaymentCount", { total: total }) }}({{ beginCount }}~{{ endCount }})</div>

    <div class="result-table">
      <el-table :data="paymentData" @row-click="paymentInfo" style="width: 100%" v-loading="loading">
        <el-table-column prop="transferDate" :label="$t('paymentDateRange')" width="150">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.transferDate, "YYYY/MM/DD") }}
          </template>
        </el-table-column>
        <el-table-column prop="bankAccountId" :label="$t('accountInfo')" width="150"> </el-table-column>
        <el-table-column prop="dateRange" :label="$t('paymentUsageTime')" min-width="220">
          <template slot-scope="scope">
            <div>
              {{
                dateFormat(scope.row.settleBeginDate, "YYYY/MM/DD") +
                  "~" +
                  dateFormat(scope.row.settleEndDate, "YYYY/MM/DD")
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="totalAmount" :label="$t('paymentTotalAmount')" min-width="150">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.successAmount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="refundAmount" :label="$t('paymentRefund')" width="150">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.refundAmount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="totalControlAmount" :label="$t('totalDeductionAmount')" width="150">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.chargeAmount + scope.row.chargeAmountTax + scope.row.transferFee) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="totalControlAmount" :label="$t('givePointAmount')" width="150">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.pointChargeAmount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="totalControlAmount" :label="$t('reviseAmount')" width="150">
          <template slot-scope="scope">
            <div>{{ moneyFormat(scope.row.reviseAmount) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="transferAmount" :label="$t('transferAmount')" min-width="150">
          <template slot-scope="scope">
            <div class="fw600">{{ moneyFormat(scope.row.transferAmount) }}</div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="right" fixed="right">
          <template>
            <div class="detail-button">
              <span>
                {{ $t("detail") }}
              </span>
              <svg-icon icon-class="expand" class-name="handle-icon"></svg-icon>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <SideLayer :showSideLayer.sync="showDetails" @closeSideLayer="toCloseSideLayer">
      <template>
        <div class="result-title">
          {{ $t("paymentDetail") }}
        </div>
        <div class="explain" v-permission="'Settle.SettleDownload'">
          {{ $t("paymentDetailMore") }}
        </div>
        <el-button
          class="confirm"
          icon="el-icon-download"
          style="margin-top: 16px;"
          :disabled="btnLoading"
          :loading="btnLoading"
          v-permission="'Settle.SettleDownload'"
          v-if="'Settle.SettleDownload'"
          @click="downloadClick"
        >
          {{ $t("paymentDetailMoreDownload") }}
        </el-button>

        <div class="name-value" style="margin-top: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">{{ $t("payDate") }}</td>
              <td class="value">{{ dateFormat(paymentDetails.transferDate, "YYYY/MM/DD") }}</td>
            </tr>

            <tr>
              <td class="name">{{ $t("acceptDateRange") }}</td>
              <td class="value">
                {{
                  dateFormat(paymentDetails.settleBeginDate, "YYYY/MM/DD") +
                    "~" +
                    dateFormat(paymentDetails.settleEndDate, "YYYY/MM/DD")
                }}
              </td>
            </tr>

            <tr>
              <td class="name">{{ $t("acceptCount") }}</td>
              <td class="value">{{ moneyFormat(paymentDetails.successAmount) }}</td>
            </tr>
            <tr>
              <td class="name">{{ $t("refundCount") }}</td>
              <td class="value">{{ moneyFormat(paymentDetails.refundAmount) }}</td>
            </tr>
            <tr>
              <td class="name">{{ $t("totalDeductionAmount") }}</td>
              <td class="value">
                {{
                  moneyFormat(paymentDetails.chargeAmount + paymentDetails.chargeAmountTax + paymentDetails.transferFee)
                }}
              </td>
            </tr>
            <tr>
              <td class="name" style="padding-left:40px;">{{ $t("settlementFee") }}</td>
              <td class="value" style="padding-left:40px;">{{ moneyFormat(paymentDetails.chargeAmount) }}</td>
            </tr>
            <tr>
              <td class="name" style="padding-left:40px;">{{ $t("chargeFee") }}</td>
              <td class="value" style="padding-left:40px;">
                {{ moneyFormat(paymentDetails.chargeAmountTax) }} <br />
                <span class="tips">{{ $t("freeTax") }}</span>
              </td>
            </tr>
            <tr>
              <td class="name" style="padding-left:40px;">{{ $t("transferFee") }}</td>
              <td class="value" style="padding-left:40px;">
                {{ moneyFormat(paymentDetails.transferFee) }}
              </td>
            </tr>
            <tr v-if="paymentDetails.hasPoint">
              <td class="name">{{ $t("givePointAmount") }}</td>
              <td class="value fw600">{{ moneyFormat(paymentDetails.pointChargeAmount) }}</td>
            </tr>
            <tr>
              <td class="name">{{ $t("reviseAmount") }}</td>
              <td class="value fw600">{{ moneyFormat(paymentDetails.reviseAmount) }}</td>
            </tr>
            <tr>
              <td class="name">{{ $t("transferCount") }}</td>
              <td class="value fw600">{{ moneyFormat(paymentDetails.transferAmount) }}</td>
            </tr>
            <tr>
              <td class="name">{{ $t("accountInfo") }}</td>
              <td class="value">
                {{ paymentDetails.bankAccountId }}
              </td>
            </tr>
          </table>
        </div>
        <!-- <div class="result-title">
          {{ $t("paymentShopDetail") }}
        </div> -->
        <!-- <withpointshop-list v-if="showDetails" :acctName="paymentDetails.id"></withpointshop-list> -->
      </template>
    </SideLayer>
    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
// import withpointshopList from "./withpointshopList";
import { accountList } from "api/account";
import { depositList, downloadDepositFile } from "api/payment";
import { executeDownload } from "utils/download";
import { moneyFormat } from "utils/index";
import pagination from "@/mixins/pagination";
import { dateFormat } from "utils/date";
export default {
  mixins: [pagination],
  // components: {
  //   withpointshopList
  // },
  data() {
    return {
      format: "yyyy/MM/dd",
      searchDate: [],
      acctName: "",
      accountList: [],
      paymentData: [],
      paymentDetails: {},
      showDetails: false,
      loading: true,
      btnLoading: false,
      beginCount: 0,
      endCount: 0,
      downloadDialog: false
    };
  },
  mounted() {
    this.searchDate = [
      this.moment()
        .startOf("month")
        .format("yyyy/MM/DD"),
      this.moment()
        .endOf("day")
        .format("YYYY/MM/DD")
    ];
    this.$forceUpdate();
    this.getAccountList();
    this.getList();
  },
  methods: {
    moneyFormat,
    dateFormat,
    downloadClick() {
      //SMCC及AEON只能下载压缩包
      if (this.$settings.oem && (this.$settings.oem === "smcc" || this.$settings.oem === "aeon")) {
        this.downloadData(2);
      } else {
        this.downloadDialog = true;
      }
    },
    downloadData(fileType) {
      let that = this;
      that.downloadDialog = false;
      that.btnLoading = true;
      let remittanceType = that.paymentDetails.transferAmount >= 0 ? 0 : 1;
      downloadDepositFile({
        remittanceId: that.paymentDetails.id,
        remittanceType,
        fileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.$message.success(this.$t("success"));
        }
        this.btnLoading = false;
      });
    },
    resetSearchData() {
      this.acctName = "";
      this.searchDate = [
        this.moment()
          .startOf("month")
          .format("yyyy/MM/DD"),
        this.moment()
          .endOf("month")
          .format("yyyy/MM/DD")
      ];
    },
    getList(pageIndex = this.currentPage, pageSize = this.currentPageSize) {
      let that = this;
      that.loading = true;
      this.currentPage = pageIndex;
      depositList({
        beginDate: that.searchDate[0],
        endDate: that.searchDate[1],
        acctId: that.acctName,
        pageIndex,
        pageSize
      }).then(response => {
        that.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          that.paymentData = response.result;
          that.total = response.totalCount;
          if (response.result.length) {
            that.beginCount = (pageIndex - 1) * pageSize + 1;
            that.endCount = that.beginCount + response.result.length - 1;
          }
        }
      });
    },
    getAccountList() {
      accountList().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          if (response.result.length > 0) {
            this.accountList = response.result;
            this.$forceUpdate();
          }
        }
      });
    },
    paymentInfo(val) {
      this.showDetails = true;
      this.paymentDetails = val;
      // this.paymentDetails.transferDate = this.paymentDetails.transferDate.split(" ")[0];
    },
    toCloseSideLayer() {
      this.showDetails = false;
    }
  }
};
</script>

<style scoped>
.payment-title {
  font-size: 26px;
  font-weight: 600;
}

.search-date {
  width: 100%;
}

.app-search-label {
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}

/* .app-search-label >>> .el-form-item__content {
  width: calc(100% - 10px) !important;
} */

.search-select {
  width: 100%;
}

.fw600 {
  font-weight: bold;
}
.name {
  width: 35%;
}
.tips {
  font-size: 14px;
  color: #999;
}
</style>
