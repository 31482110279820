import http from "../http";

export const accountList = () => {
  return http({
    method: "get",
    url: `/api/settlement/bankaccountlist`
  });
};
// 查询多企业银行账户信息列表;
export const enterprisesBankAccountList = params => {
  return http({
    method: "get",
    url: `/api/settlement/enterprisesbankaccountlist`,
    params
  });
};
