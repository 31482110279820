import moment from "moment";
/**
 * 格式化日期
 * @param {String} fmt [日期类型 默认为年月日（yyyy/MM/dd hh:mm:ss）]
 */
export function dateFormat(fmtData, fmt = "YYYY/MM/DD HH:mm:ss") {
  return moment(fmtData).format(fmt);
}
/**
 * 获取当前月的最后一天
 * @param {String} fmt [日期类型 默认为年月日（yyyy/MM/dd hh:mm:ss）]
 */
export function getCurrentMonthLast(format = "yyyy/MM/dd hh:mm:ss") {
  return moment()
    .endOf("month")
    .format(format);
}
/**
 * 获取当前月的第一天
 * @param {String} fmt [日期类型 默认为年月日（yyyy/MM/dd hh:mm:ss）]
 */
export function getCurrentMonthFirst(format = "yyyy/MM/dd hh:mm:ss") {
  return moment()
    .startOf("month")
    .format(format);
}

//相差月数
export function diffMonths(date1, date2) {
  const a = moment(date1, "YYYY-MM-DD").startOf("month");
  const b = moment(date2, "YYYY-MM-DD").startOf("month");
  return Math.abs(b.diff(a, "months"));
}
//相差天数
export function diffDays(date1, date2) {
  const a = moment(date1, "YYYY-MM-DD");
  const b = moment(date2, "YYYY-MM-DD");
  return Math.abs(b.diff(a, "days"));
}

// 获取当天的开始结束时间，精确到时分秒
export function getToday() {
  let date = [];
  date.push(
    moment()
      .startOf("days")
      .format("YYYY-MM-DD HH:mm:ss")
  );
  date.push(
    moment()
      .endOf("days")
      .format("YYYY-MM-DD HH:mm:ss")
  );
  return date;
}

// 获取昨天的开始结束时间，精确到时分秒
export function getYesterday() {
  let date = [];
  date.push(
    moment()
      .add(-1, "days")
      .format("YYYY-MM-DD 00:00:00")
  );
  date.push(
    moment()
      .add(-1, "days")
      .format("YYYY-MM-DD 23:59:59")
  );
  return date;
}

// 获取当前周的开始结束时间
export function getCurrWeek() {
  var currentDate = moment();
  let date = [];
  var start = currentDate
    .clone()
    .startOf("isoWeek")
    .format("YYYY-MM-DD 00:00:00");
  var end = currentDate
    .clone()
    .endOf("isoWeek")
    .format("YYYY-MM-DD 23:59:59");
  date.push(start);
  date.push(end);
  return date;
}
// 获取当前月的开始结束时间
export function getCurrMonth() {
  let date = [];
  let start = moment()
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss");
  let end = moment()
    .endOf("month")
    .format("YYYY-MM-DD HH:mm:ss");
  date.push(start);
  date.push(end);
  return date;
}
// 获取上一月的开始结束时间
export function getLastMonth() {
  let date = [];
  let start = moment()
    .month(moment().month() - 1)
    .startOf("month")
    .format("YYYY-MM-DD HH:mm:ss");
  let end = moment()
    .month(moment().month() - 1)
    .endOf("month")
    .format("YYYY-MM-DD HH:mm:ss");
  date.push(start);
  date.push(end);
  return date;
}
// 当前季度
export function getCurrQuarter() {
  let date = [];
  let start = moment()
    .startOf("quarter")
    .format("YYYY-MM-DD HH:mm:ss");
  let end = moment()
    .endOf("quarter")
    .format("YYYY-MM-DD HH:mm:ss");
  date.push(start);
  date.push(end);
  return date;
}
// 上个季度
export function getLastQuarter() {
  let date = [];
  let start = moment()
    .quarter(moment().quarter() - 1)
    .startOf("quarter")
    .format("YYYY-MM-DD HH:mm:ss");
  let end = moment()
    .quarter(moment().quarter() - 1)
    .endOf("quarter")
    .format("YYYY-MM-DD HH:mm:ss");
  date.push(start);
  date.push(end);
  return date;
}

// 今年开始结束日期
export function getCurrentYear() {
  let date = [];
  let start = moment()
    .year(moment().year())
    .startOf("year")
    .format("YYYY-MM-DD HH:mm:ss");
  let end = moment()
    .year(moment().year())
    .endOf("year")
    .format("YYYY-MM-DD HH:mm:ss");
  date.push(start);
  date.push(end);
  return date;
}
